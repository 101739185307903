<template>
  <auth-card btn-classes="full-width"
    :show-close-btn="true"
    link="/">
    <template #description>
      <el-form ref="termsForm"
        class="text-align-left"
        :model="termsForm"
        :rules="termsRules"
        label-position="top">
        <el-form-item prop="agreement">
          <el-checkbox id="agreeToTerms"
            v-model="termsForm.agreement"
            name="agreeToTerms">
            <span class="terms">
              {{ $t('authentication.agree-to') }}
              <a href="https://www.megaport.com/legal/privacy-policy/"
                target="_blank"
                rel="noopener">{{ $t('general.privacy-policy') }}</a>,
              <a href="https://www.megaport.com/legal/site-terms"
                target="_blank"
                rel="noopener">{{ $t('general.site-terms') }}</a>,
              <a href="https://www.megaport.com/legal/acceptable-use-policy/"
                target="_blank"
                rel="noopener">{{ $t('general.acceptable-use') }}</a>
            </span>
          </el-checkbox>
        </el-form-item>
      </el-form>

      <el-button class="full-width"
        @click="continueToMegaport">
        <div class="flex-row-centered flex-justify-center">
          <div>{{ $t('authentication.continue-to-megaport') }}</div>
        </div>
      </el-button>
    </template>
  </auth-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import AuthCard from '@/components/ui-components/AuthCard.vue'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'

export default {
  name: 'SignupGoogle',

  components: {
    'auth-card': AuthCard,
  },

  data() {
    return {
      loader: null,
      termsForm: {
        agreement: false,
      },
      termsRules: {
        agreement: { required: true, validator: this.validateBooleanTrue, trigger: 'change' },
      },
    }
  },

  computed: {
    ...mapState('Services', ['services']),
  },

  created() {
    // set to indicate that the terms on this page must be agreed to before you can access the portal.
    localStorage.setItem('_requiresTerms', true)
  },

  methods: {
    ...mapMutations('Auth', ['setRequiresTerms']),
    ...mapActions('Services', ['getMyServices']),
    ...mapMutations('Notifications', ['notifyBad']),
    /**
     * Validate agreement checkbox is checked
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateBooleanTrue(_rule, value, callback) {
      if (typeof value !== 'boolean' || !value) {
        callback(new Error(this.$t('validations.terms-conditions')))
      } else {
        callback()
      }
    },
    /**
     * Sign up the user
     */
    continueToMegaport() {
      this.$refs['termsForm'].validate(valid => {
        if (!valid) {
          this.notifyBad({ title: this.$t('authentication.signup-failed') })
          return
        }

        localStorage.removeItem('_requiresTerms')

        this.loader = this.$loading({
          lock: true,
          text: this.$t('google-login.token-received'),
          background: 'rgba(255, 255, 255, 0.9)',
        })

        this.getMyServices()
          .finally(() => {
            this.loader.close()
            this.$router.push(resolveServicesPage())
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-icon-info {
  font-size: 1.6rem;
  line-height: initial;
}
</style>
